import { createRouter, createWebHashHistory } from "vue-router";
import Home from "@/views/HomeView.vue";

const routes = [
  {
    meta: {
      title: "Dashboard",
      requiresAuth: true,
    },
    path: "/",
    name: "dashboard",
    component: Home,
  },
  {
    meta: {
      title: "Reviews",
      requiresAuth: true,
    },
    path: "/reviews",
    name: "reviews",
    component: () => import("@/views/ReviewsView.vue"),
  },
  {
    meta: {
      title: "Competitors",
      requiresAuth: true,
    },
    path: "/competitors",
    name: "competitors",
    component: () => import("@/views/CompetitorView.vue"),
  },
  {
    meta: {
      title: "Socials",
      requiresAuth: true,
    },
    path: "/socials",
    name: "socials",
    component: () => import("@/views/CompetitorView.vue"),
  },
  {
    meta: {
      title: "Forms",
    },
    path: "/forms",
    name: "forms",
    component: () => import("@/views/FormsView.vue"),
  },
  {
    meta: {
      title: "Tracked Entities",
    },
    path: "/tracked-entities",
    name: "tracked-entities",
    component: () => import("@/views/TrackedEntitiesView.vue"),
  },
  {
    meta: {
      title: "Profile",
    },
    path: "/profile",
    name: "profile",
    component: () => import("@/views/ProfileView.vue"),
  },
  {
    meta: {
      title: "Ui",
    },
    path: "/ui",
    name: "ui",
    component: () => import("@/views/UiView.vue"),
  },
  {
    meta: {
      title: "Login",
    },
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    meta: {
      title: "Sign Up",
    },
    path: "/signup",
    name: "signup",
    component: () => import("@/views/SignupView.vue"),
  },
  {
    meta: {
      title: "Error",
    },
    path: "/error",
    name: "error",
    component: () => import("@/views/ErrorView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});

export default router;
