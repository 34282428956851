<script setup>
import { mdiLogout, mdiClose } from "@mdi/js";
import { computed } from "vue";
import { useStyleStore } from "@/stores/style.js";
import AsideMenuList from "@/components/AsideMenuList.vue";
import AsideMenuItem from "@/components/AsideMenuItem.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import buzzTrackerLogo from "@/assets/buzztracker-logo.png";
import CompanySelect from "@/components/CompanySelect.vue";
import { useMainStore } from "@/stores/main.js";


defineProps({
  menu: {
    type: Array,
    required: true,
  },
});

const mainStore = useMainStore();

const emit = defineEmits(["menu-click", "aside-lg-close-click"]);

const styleStore = useStyleStore();

const logoutItem = computed(() => ({
  label: "Logout",
  icon: mdiLogout,
  isLogout: true,
}));

const menuClick = (event, item) => {
  emit("menu-click", event, item);
};

const asideLgCloseClick = (event) => {
  emit("aside-lg-close-click", event);
};
</script>

<template>
  <aside
    id="aside"
    class="w-60 fixed flex z-40 top-0 h-screen transition-position overflow-hidden"
  >
    <div
      :class="styleStore.asideStyle"
      class="lg:rounded-r-2xl flex-1 flex flex-col overflow-hidden bg-white dark:bg-slate-900"
    >
      <div
        :class="styleStore.asideBrandStyle"
        class="flex flex-row h-14 items-center justify-between bg-white dark:bg-slate-900"
      >
        <div class="flex items-center justify-center">
          <img :src="buzzTrackerLogo" alt="BuzzTracker Logo" class="mx-3 h-auto max-h-8" />
          <div class="text-center">
            <b class="font-black">BuzzTracker</b>
          </div>
        </div>
        <button
          class="hidden lg:inline-block xl:hidden p-3"
          @click.prevent="asideLgCloseClick"
        >
          <BaseIcon :path="mdiClose" />
        </button>
      </div>

      <hr class="w-11/12 h-0.5 mx-auto mb-1 bg-gray-100 border-0 rounded dark:bg-gray-700">

      <div class="font-bold leading-tight hidden xl:block">
        <CompanySelect/>
      </div>

      <div
        :class="
          styleStore.darkMode
            ? 'aside-scrollbars-[slate]'
            : styleStore.asideScrollbarsStyle
        "
        class="flex-1 overflow-y-auto overflow-x-hidden mt-1"
      >
        <AsideMenuList :menu="menu" @menu-click="menuClick" />
      </div>

      <ul>
        <AsideMenuItem :item="logoutItem" @menu-click="menuClick" />
      </ul>
    </div>
  </aside>
</template>
